//
//
//  Admin
//
//

import { Outlet } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout.tsx";


function Admin() {
    return (
        <DashboardLayout>
            <Outlet/>
        </DashboardLayout>
    )
}

export default Admin
