import './App.css'
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import RequireLoggedIn from './components/RequireLoggedIn.tsx';
import {Auth0Provider} from "@auth0/auth0-react";
import '@mantine/notifications/styles.css'
import {Notifications} from "@mantine/notifications";

import Dashboard from "./routes/dashboard/Dashboard.tsx";
import DashboardList from "./routes/dashboard/List.tsx";
import DashboardOverview from "./routes/dashboard/Overview.tsx";
import DashboardProject from "./routes/dashboard/Project.tsx";
import Admin from "./routes/admin/Admin.tsx";
import AdminProjectsList from './routes/admin/projects/List.tsx';
import AdminServicesList from './routes/admin/services/List.tsx';


function App() {
    const navigate = useNavigate()
    const onRedirectCallback = (appState: any) => {
        navigate((appState && appState.returnTo) || window.location.pathname)
    }

    return (
        <>
        <Auth0Provider
             domain={import.meta.env.VITE_AUTH0_DOMAIN}
             clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
             authorizationParams={{
                 redirect_uri: window.location.origin,
                 audience: import.meta.env.VITE_AUTH0_AUDIENCE
             }}
             onRedirectCallback={onRedirectCallback}
             cacheLocation="localstorage"
        >
            <Routes>
                <Route element={<RequireLoggedIn/>}>
                    <Route
                        path="/"
                        element={<Navigate to="/dashboard" replace/>}
                    />
                    <Route path="/dashboard" Component={Dashboard}>
                        <Route index Component={DashboardList}/>
                        <Route path="overview" Component={DashboardOverview}/>
                        <Route path="project/:projectId" Component={DashboardProject}/>
                    </Route>

                    <Route path="/admin" Component={Admin}>
                        <Route index element={<Navigate to="projects" />}></Route>
                        <Route path="services" Component={AdminServicesList}/>
                        <Route path="projects" Component={AdminProjectsList}/>
                    </Route>
                </Route>
            </Routes>
        </Auth0Provider>
        <Notifications />
        </>
    )
}

export default App
