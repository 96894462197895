//
//
//   Dashboard Layout
//
//


import { Anchor, AppShell, Container, Flex, Image, Text } from "@mantine/core";
import { ReactNode } from "react";
import classes from "./DashboardLayout.module.css";
import { Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import HeaderMenu from "../components/HeaderMenu";
import {IconDevices2, IconCodeDots} from "@tabler/icons-react";
import NavbarLink from "../components/NavbarLink.tsx";
import {NavbarSection, NavbarSectionItem} from "../interfaces.ts";

function DashboardLayout({ children }: { children: ReactNode }) {
    const { pathname } = useLocation();
    const { logout } = useAuth0()

    const pathparts = /\/admin\/(\w*)\/?/.exec(pathname);
    let active: string;
    let isAdmin = false;

    if (!pathparts) {
        active = "home";
    } else {
        active = pathparts[1];
        isAdmin = true;
    }

    

    function onLogout() {
    logout()
        .catch(console.error)
    }


    const sections: NavbarSection[] = [
        {
            label: undefined,
            items: [
                { icon: <IconDevices2 size={16} />, color: 'blue', label: "Services", to: "/admin/services", active: "services"},
                { icon: <IconCodeDots size={16} />, color: 'red', label: "Projects", to: "/admin/projects", active: "projects"}
            ]
        }
    ]

    const navbarProps = isAdmin ? { width: 300, breakpoint: 0 } : undefined;


    return (
        <AppShell header={{ height: 60 }} navbar={navbarProps}>
            <AppShell.Header>
                <Flex justify="space-between" align="center" direction={'row'} h={'100%'} p={20} >
                    <Anchor c="dimmed" size="sm" component={Link} to="/">

                        <Image
                        radius="md"
                        h={50}
                        w="auto"
                        fit="contain"
                        src={'https://storage.googleapis.com/maia-billing-assets/maia-billing.svg'}
                    />
                    </Anchor>
                    <HeaderMenu
                        onLogout={onLogout}
                    />
                </Flex>
            </AppShell.Header>
            {isAdmin &&
                <AppShell.Navbar>
                    <Flex direction="column" mt="md" className={classes.navbarMainSection}>
                        {sections.map((section: NavbarSection, sectionIndex: number) => {
                            return (
                                <Flex direction="column" key={sectionIndex}>
                                    {section.label != null &&
                                        <Text size="xs" w={500} c="dimmed" className={classes.navbarSectionHeader}>
                                            {section.label}
                                        </Text>
                                    }
                                    {section.items.map((item: NavbarSectionItem, itemIndex: number) => {
                                        return (
                                            <NavbarLink {...item} active={active === item.active} key={itemIndex}/>
                                        )
                                    })}
                                </Flex>
                            )
                        })}
                    </Flex>
                </AppShell.Navbar>
            }
            <AppShell.Main className={classes.main}>
        <Container className={classes.container}>{children}</Container>
            </AppShell.Main>
        </AppShell>
    );
}

export default DashboardLayout;
