//
//
//  Overview
//
//

//
//
//  Project
//
//

//
//
//  Project
//
//

import { Link } from "react-router-dom";
import {
  Anchor,
  Box,
  Flex,
  Grid,
  Image,
  LoadingOverlay,
  Skeleton,
  rem,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { ChartData, Project as ProjectInterface } from "../../interfaces.ts";
import { DateRangePicker, DateRangePickerValue } from "@tremor/react";
import Api from "../../api.ts";
import { useAuth0 } from "@auth0/auth0-react";
import { formatDate } from "../../utils.ts";
import TotalBillingChart from "../../components/TotalBillingChart.tsx";
import TotalCostProjectCard from "../../components/TotalCostProjectCard.tsx";

function DashboardOverview() {
  const today = new Date();
  const previousMonth = new Date();
  previousMonth.setMonth(previousMonth.getMonth() - 1);
  const { getAccessTokenSilently } = useAuth0();
  const [projects, setProjects] = useState<ProjectInterface[]>([]);
  const [chartDataMap, setChartDataMap] = useState<ChartData[] | null>(null);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [isInstanceLoading, setIsInstanceLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRangePickerValue>({
    from: previousMonth,
    to: today,
  });

  async function getAccessToken() {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      },
    });

    return accessToken;
  }

  function getDatesBetweenDates(startDate: Date, endDate: Date) {
    const dates = [];
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        dates.push(formatDate(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
}

  async function fetchTotalCost(dateRange: any) {
    if (projects.length > 0) {
      setIsInstanceLoading(true)

      const accessToken = await getAccessToken();
      const params = new URLSearchParams({
        start_date: formatDate(dateRange.from),
        end_date: formatDate(dateRange.to),
      });

      const newChartDataMap = new Map();
      let costCount = 0
      for (const project of projects) {
        await Api.getProjectCostDaily(accessToken, project.id, params).then(
          (data) => {
            for (const dailyData of data) {
                costCount += dailyData.cost
              if (newChartDataMap.has(dailyData.date)) {
                const existingData = newChartDataMap.get(dailyData.date);
                existingData[project.name] = dailyData.cost;
              } else {
                const newData = {
                  date: dailyData.date,
                  [project.name]: dailyData.cost,
                };
                newChartDataMap.set(dailyData.date, newData);
              }
            }
          }
        );
      }
      

      const allDates = getDatesBetweenDates(dateRange.from, dateRange.to);

      const chartDataArray: ChartData[] = Array.from(allDates, (date) => {
        const projectData = newChartDataMap.get(date) || {};
    
        return {
            date: date,
            ...projectData,
        };
    });

      setChartDataMap(chartDataArray);
      setIsInstanceLoading(false);
      setTotalCost(costCount)
    }
  }

  async function getProjects() {
    const accessToken = await getAccessToken();
    await Api.getProjects(accessToken, 1, 99).then((data) => {
      setProjects(data.items);
    });
  }

  useEffect(() => {
    fetchTotalCost(dateRange);
  }, [dateRange, projects]);

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        direction="row"
        mt={rem(50)}
        mb={rem(30)}
      >
        <Flex className="mt-2" direction={"column"}>
          <Flex justify={"center"} align={"center"} gap={14} direction={"row"}>
          <Image
            radius="md"
            h={60}
            w="auto"
            fit="contain"
            src={'https://storage.googleapis.com/maia-billing-assets/maia-cognitive.svg'}
            />
          </Flex>
          <Anchor c="dimmed" size="sm" className="mt-5" component={Link} to="/dashboard">
            <Flex align="center">
              <IconArrowLeft
                style={{ width: rem(12), height: rem(12) }}
                stroke={1.5}
              />
              <Box ml={5}>Back to Dashboard</Box>
            </Flex>
          </Anchor>
        </Flex>
        <Flex justify={"center"} direction={"column"} gap={14}>
          <TotalCostProjectCard
            label={`Total Cost`}
            totalCost={totalCost}
          />
        </Flex>
      </Flex>
      <Flex justify={'end'}>
        <DateRangePicker
            value={dateRange}
            onValueChange={setDateRange}
            weekStartsOn={1}
            enableClear={false}
        />
      </Flex>
      <Skeleton className="mt-10" visible={isInstanceLoading} height={500}>
        <Grid className="mb-10">
          <TotalBillingChart
            data={chartDataMap}
            categories={projects.map((project) => project.name)}
          />
        </Grid>
      </Skeleton>
      <LoadingOverlay visible={isInstanceLoading} loaderProps={{ size: "xl", variant: "oval" }} />
    </>
  );
}

export default DashboardOverview;
