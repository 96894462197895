//
//
//  Project Drawer
//
//

import {useEffect, useRef, useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Socket } from "socket.io-client";

import {Drawer, LoadingOverlay, Title} from "@mantine/core";
import {useForm} from "@mantine/form";
import {Button, TextInput, Divider} from "@mantine/core";
import {IconTrash} from "@tabler/icons-react";

import classes from "./ProjectDrawer.module.css"
import Api from "../../../api.ts";
import {Instance, Project} from "../../../interfaces.ts";
import { useApiErrorHandler } from "../../../hooks.ts";
import {useSocket} from "../../../contexts/SocketContext.tsx";
import InstancesTable from "../instances/InstancesTable.tsx";
import { onResourceAdd, onResourceDelete, onResourceUpdate } from "../../../utils.ts";


function ProjectDrawer({project, opened, onClose, onDelete, deleteLoading}: {project: Project, opened: boolean, onClose: () => void, onDelete: (project: Project) => void, deleteLoading: boolean}) {
    const { getAccessTokenSilently } = useAuth0()
    const { socket }: { socket: Socket } = useSocket()
    const handleError = useApiErrorHandler()
    const [updateLoading, setUpdateLoading] = useState(false)
    const [loadingInstances, setLoadingInstances] = useState(true)
    const instancesRef = useRef<Instance[]>([])
    const [instances, setInstances] = useState<Instance[]>([])

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    const projectMetadataForm = useForm({
        initialValues: {
            id: project.id,
            name: project.name,
            logo_url: project.logo_url
        }
    })

    async function loadProjectInstances() {
        setLoadingInstances(true)
    
        Api.getProjectInstances(await getAccessToken(), project.id)
            .then(listInstances => {
                instancesRef.current = listInstances
                setInstances(listInstances)
                setLoadingInstances(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoadingInstances(false)
            })
    }




    useEffect(() => {
        loadProjectInstances()
    }, [project.id])

    async function onUpdateProject(values: any) {
        const data = {...values}
        setUpdateLoading(true)

        Api.updateProject(await getAccessToken(), project.id, data)
            .then(() => {
                setUpdateLoading(false)
            })
    }


    useEffect(() => {
        function onInstanceAdd(added: Instance) {
            if (added.project_id === project.id) {
                instancesRef.current = onResourceAdd(added, instancesRef.current)
                setInstances(instancesRef.current)
            }
        }

        function onInstanceUpdate(updated: Instance) {
            if (updated.project_id === project.id) {
                instancesRef.current = onResourceUpdate(updated, instancesRef.current)
                setInstances(instancesRef.current)
            }
        }

        function onInstanceDelete(deleted: Instance) {
            if (deleted.project_id === project.id) {
                instancesRef.current = onResourceDelete(deleted, instancesRef.current)
                setInstances(instancesRef.current)
            }
        }

        socket.on("instance:add", onInstanceAdd)
        socket.on("instance:update", onInstanceUpdate)
        socket.on("instance:delete", onInstanceDelete)

        return () => {
            socket.off("instance:add", onInstanceAdd)
            socket.off("instance:update", onInstanceUpdate)
            socket.off("instance:delete", onInstanceDelete)
        }
    }, [socket])


    return (
        <Drawer
            opened={opened}
            onClose={onClose}
            position="right"
            className={classes.drawer}
            withinPortal={true}
            styles={{
                content: {
                    display: "flex",
                    flexDirection: "column"
                },
                body: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }
            }}
        >

            <form onSubmit={projectMetadataForm.onSubmit(onUpdateProject)}>
                <TextInput
                    label="Name"
                    mb="md"
                    {...projectMetadataForm.getInputProps("name")}
                />

                <TextInput
                    label="Logo URL"
                    mb="md"
                    {...projectMetadataForm.getInputProps("logo_url")}
                />

                <Button mt="md" type="submit" loading={updateLoading}>Save</Button>
            </form>

            <Title size="h3" mt="lg" mb="md">{"Instances"}</Title>
            <LoadingOverlay visible={loadingInstances}/>
            <InstancesTable
                project={project}
                instances={instances}
            />

            <div className={classes.content}>
                <div className={classes.footer}>
                    <Divider mt="xl" mb="xl"/>
                    <Button loading={deleteLoading} color="red" leftSection={<IconTrash size={16}/>} fullWidth onClick={() => onDelete(project)}>
                        Delete project
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}

export default ProjectDrawer
