//
//
//  Channels Data Table
//
//

import {Project} from "../../../interfaces.ts";
import {Anchor, Table} from "@mantine/core";
import {DataTableEmpty, DataTableLoading} from "../../../components/DataTable.tsx";
import {useAutoAnimate} from "@formkit/auto-animate/react";


function ProjectsDataTable(
    {projects, newProjects, loading=false, onCreate, onSelection}: 
    {
        projects: Project[], 
        newProjects: Project[],
        loading: boolean,
        onCreate: () => void,
        onSelection: (project: Project) => void
    }
) {
    const [parent] = useAutoAnimate()

    if (loading) {
        return <DataTableLoading/>
    }

    if (!projects.length) {
        return <DataTableEmpty
            title="You don't have any project"
            subtitle=""
            action="Create your first Project"
            onAction={onCreate}
        />
    }


    return (
        <Table>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>ID</Table.Th>
                    <Table.Th>Name</Table.Th>
                    <Table.Th>Logo URL</Table.Th>
                </Table.Tr>
                </Table.Thead>
            <Table.Tbody ref={parent}>
                {newProjects.map((project: Project) => {
                    return (
                        <Table.Tr key={project.id} style={{backgroundColor: "#F5FBEF"}}>
                            <Table.Td>
                                {project.id}
                            </Table.Td>
                            <Table.Td>
                                <Anchor onClick={() => onSelection(project)}>
                                    {project.name}
                                </Anchor>
                            </Table.Td>
                            <Table.Td>
                                {project.logo_url}
                            </Table.Td>
                        </Table.Tr>
                    )
                })}

                {projects.map((project: Project) => {
                    return (
                        <Table.Tr key={project.id}>
                            <Table.Td>
                                {project.id}
                            </Table.Td>
                            <Table.Td>
                                <Anchor onClick={() => onSelection(project)}>
                                    {project.name}
                                </Anchor>
                            </Table.Td>
                            <Table.Td>
                            {project.logo_url}
                            </Table.Td>
                        </Table.Tr>
                    )
                })}
            </Table.Tbody>
        </Table>
    )
}

export default ProjectsDataTable