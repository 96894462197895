//
//
//  Channels Data Table
//
//

import {Table} from "@mantine/core";
import {DataTableEmpty, DataTableLoading} from "../../../components/DataTable.tsx";
import {useAutoAnimate} from "@formkit/auto-animate/react";


function ServicesDataTable(
    {services, loading=false, onCreate}: 
    {
        services: string[],
        loading: boolean,
        onCreate: () => void
    }
) {
    const [parent] = useAutoAnimate()

    if (loading) {
        return <DataTableLoading/>
    }

    if (!services.length) {
        return <DataTableEmpty
            title="You don't have any service"
            subtitle=""
            action="Create your first Service"
            onAction={onCreate}
        />
    }


    return (
        <Table>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>Name</Table.Th>
                </Table.Tr>
                </Table.Thead>
            <Table.Tbody ref={parent}>
                {services.map((service: string) => {
                    return (
                        <Table.Tr key={service}>
                            <Table.Td>
                                {service}
                            </Table.Td>
                        </Table.Tr>
                    )
                })}
            </Table.Tbody>
        </Table>
    )
}

export default ServicesDataTable