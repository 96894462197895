import { useEffect, useRef, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { Socket } from "socket.io-client";

import { useForm } from "@mantine/form"
import { ActionIcon, ComboboxItem, Divider, Flex, LoadingOverlay, Modal, Select, Table, TextInput, Title, Text } from "@mantine/core"
import { IconPlus, IconTrash } from "@tabler/icons-react"

import { Instance, InstanceService } from "../../../interfaces"
import Api from "../../../api"
import { useApiErrorHandler } from "../../../hooks"
import {useSocket} from "../../../contexts/SocketContext.tsx";
import { onResourceAdd, onResourceDelete, onResourceUpdate } from "../../../utils.ts";



interface InstanceServiceForm {
    service: string | null,
    referenceId: string
}


function InstanceModal({instance, opened, onClose}: {instance: Instance, opened: boolean, onClose: () => void}) {
    const [parent] = useAutoAnimate()
    const { getAccessTokenSilently } = useAuth0()
    const { socket }: { socket: Socket } = useSocket()
    const handleError = useApiErrorHandler()
    const [loading, setLoading] = useState(false)
    const [servicesCombobox, setServicesCombobox] = useState<ComboboxItem[]>([])
    const [instanceServices, setInstanceServices] = useState<InstanceService[]>([])
    const instanceServicesRef = useRef<InstanceService[]>([])

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    const createInstanceServiceform = useForm<InstanceServiceForm>({
        initialValues: {
            service: null,
            referenceId: ""
        }
    })

    async function loadInstanceServices() {
        setLoading(true)
    
        Api.getInstanceServices(await getAccessToken(), instance.id)
            .then(list => {
                instanceServicesRef.current = list
                setInstanceServices(list)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }

    async function onSubmitCreateInstanceService(values: any) {
        Api.createInstanceService(
            await getAccessToken(),
            instance.id,
            values.service,
            values.referenceId
        )
            .then(() => {
                createInstanceServiceform.reset()
            }).catch((err) => {
                console.error(err);
                handleError(err)
            })
    }

    async function onDeleteInstanceService(instanceServiceId: number) {
        Api.deleteInstanceService(await getAccessToken(), instanceServiceId)
            .catch(console.error)

    }

    async function loadServices() {
        Api.getServices(await getAccessToken())
            .then(services => {
                const aux = services.map((service: string) => {
                    return {"label": service, "value": service} as ComboboxItem
                })
                if (aux !== undefined) {
                    setServicesCombobox(aux)
                }
                setLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        loadServices()
        loadInstanceServices()
    }, [instance.id])


    useEffect(() => {
        function onInstanceServiceAdd(added: InstanceService) {
            if (added.instance_id === instance.id) {
                instanceServicesRef.current = onResourceAdd(added, instanceServicesRef.current)
                setInstanceServices(instanceServicesRef.current)
            }
        }

        function onInstanceServiceUpdate(updated: InstanceService) {
            if (updated.instance_id === instance.id) {
                instanceServicesRef.current = onResourceUpdate(updated, instanceServicesRef.current)
                setInstanceServices(instanceServicesRef.current)
            }
        }

        function onInstanceServiceDelete(deleted: InstanceService) {
            if (deleted.instance_id === instance.id) {
                instanceServicesRef.current = onResourceDelete(deleted, instanceServicesRef.current)
                setInstanceServices(instanceServicesRef.current)
            }
        }

        socket.on("instanceservice:add", onInstanceServiceAdd)
        socket.on("instanceservice:update", onInstanceServiceUpdate)
        socket.on("instanceservice:delete", onInstanceServiceDelete)

        return () => {
            socket.off("instanceservice:add", onInstanceServiceAdd)
            socket.off("instanceservice:update", onInstanceServiceUpdate)
            socket.off("instanceservice:delete", onInstanceServiceDelete)
        }
    }, [socket])
    
    return (
        <Modal
            opened={opened}
            onClose={onClose}
            size="xl"
            title="Instance services"
            styles={{
                content: {
                    display: "flex",
                    flexDirection: "column"
                },
                body: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }
            }}
        >
            <Title size="h3" mb="md">{instance.tag}</Title>
            <LoadingOverlay visible={loading}/>
            <Divider my="sm" />

            <form onSubmit={createInstanceServiceform.onSubmit(onSubmitCreateInstanceService)}>
                <Flex align={"center"}>
                    <Select
                        mr={"sm"}
                        withAsterisk
                        data={servicesCombobox}
                        allowDeselect={false}
                        required
                        placeholder="Service"
                        {...createInstanceServiceform.getInputProps("service")}
                    />

                    <TextInput
                        mr={"sm"}
                        withAsterisk
                        required
                        placeholder="Reference ID"
                        {...createInstanceServiceform.getInputProps("referenceId")}
                    />

                    <ActionIcon type="submit">
                        <IconPlus size={14} />
                    </ActionIcon>
                </Flex>
            </form>

            {instanceServices && (
                <Table mt="md" withTableBorder>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Service</Table.Th>
                            <Table.Th>Reference ID</Table.Th>
                            <Table.Th>Delete?</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody ref={parent}>
                        {instanceServices.map(instanceService => {
                            return (
                                <Table.Tr key={instanceService.id}>
                                    <Table.Td>
                                        <Text>{instanceService.service}</Text>
                                    </Table.Td>
                                    <Table.Td>
                                    <Text>{instanceService.reference_id}</Text>
                                    </Table.Td>
                                    <Table.Td>
                                        <ActionIcon variant="subtle" color="red" aria-label={"Delete"} onClick={() => onDeleteInstanceService(instanceService.id)}>
                                            <IconTrash size={16} />
                                        </ActionIcon>
                                    </Table.Td>
                                </Table.Tr>
                            )
                        })}
                    </Table.Tbody>
                </Table>
            )}
        </Modal>
    )
}

export default InstanceModal