//
//
//  Azure Chart
//
//

import { BarChart, Card, Metric } from "@tremor/react";
import { Flex } from "@mantine/core";
import { ChartData } from "../interfaces";
import { orderByDateAsc } from "../utils";


function TotalBillingChart({
  data,
  categories,
}: {
  data: ChartData[] | null;
  categories: string[];
}) {
  return (
    <Card>
      <Flex justify="space-between">
       
        <Flex direction="column" gap={10}>
          <Metric className="text-right"></Metric>
        </Flex>
      </Flex>
      {data != null && (
        <BarChart
          className="h-72 mt-4"
          data={orderByDateAsc(data)}
          index="date"
          stack={true}
          categories={categories}
          autoMinValue={true}
        />
      )}
    </Card>
  );
}

export default TotalBillingChart;
