//
//
//  Service List
//
//

import {useEffect, useRef, useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Container,
    Flex,
    Modal,
    rem,
    Title
} from "@mantine/core";
import Api from "../../../api.ts"
import ServicesDataTable from "./DataTable.tsx";
//import {useSocket} from "../../../contexts/SocketContext.tsx";
import {/*onResourceAdd, onResourceDelete, onResourceUpdate,*/ sortByUpdatedAt} from "../../../utils.ts";
import PaginationControl from "../../../components/PaginationControl.tsx";


function AdminServicesList() {
    const pageLength = 15

    const { getAccessTokenSilently } = useAuth0()
    //const {socket} = useSocket()
    const servicesRef = useRef<string[]>([])
    const [services, setServices] = useState<string[]>([])
    const [loading, setLoading] = useState(true)
    const [totalServices, setTotalServices] = useState(0)
    const [page, setPage] = useState(1)
    const [createOpen, setCreateOpen] = useState(false)

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    async function loadServices() {
        setLoading(true)
        Api.getServices(await getAccessToken())
            .then(services => {
                servicesRef.current = services
                setServices(sortByUpdatedAt(servicesRef.current))
                setTotalServices(servicesRef.current.length)
                setLoading(false)
            }).catch(console.error)
    }

    function handleClose() {
        setCreateOpen(false)
    }


    useEffect(() => {
        loadServices()
    }, [page])


    return (
        <>
            <main>
                <Container>
                    <Flex
                        justify="space-between"
                        align="center"
                        direction="row"
                        mt={rem(50)}
                        mb={rem(30)}
                    >
                        <Title size="h1">Services</Title>
                    </Flex>
                    <ServicesDataTable
                        services={services}
                        loading={loading}
                        onCreate={() => setCreateOpen(true)}
                    />
                    <PaginationControl 
                        totalElements={totalServices}
                        page={page}
                        pageLength={pageLength}
                        onChange={setPage}
                    />
                </Container>
                <Modal opened={createOpen} onClose={() => handleClose()}>
                    Services have to be created in the backend
                </Modal>
            </main>
        </>
    )
}

export default AdminServicesList
