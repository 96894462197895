//
//
//  Project List
//
//

import {useEffect, useRef, useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    Button,
    Container,
    Flex,
    rem,
    Title
} from "@mantine/core";
import {IconCirclePlus} from "@tabler/icons-react"
import { Socket } from "socket.io-client";

import Api from "../../../api.ts"
import ProjectsDataTable from "./DataTable.tsx";
import {Project} from "../../../interfaces.ts";
import {useSocket} from "../../../contexts/SocketContext.tsx";
import {onResourceAdd, onResourceDelete, onResourceUpdate, sortByUpdatedAt} from "../../../utils.ts";
import PaginationControl from "../../../components/PaginationControl.tsx";
import ProjectDrawer from "./Drawer.tsx";
import ProjectCreateModal from "./Create.tsx";


function AdminProjectsList() {
    const pageLength = 10

    const { getAccessTokenSilently } = useAuth0()
    const { socket }: { socket: Socket } = useSocket()
    const projectsRef = useRef<Project[]>([])
    const [projects, setProjects] = useState<Project[]>([])
    const [loading, setLoading] = useState(true)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [totalProjects, setTotalProjects] = useState(0)
    const [page, setPage] = useState(1)
    const newElementsRef = useRef<Project[]>([])
    const [newElements, setNewElements] = useState<Project[]>([])
    const [selectedProject, setSelectedProject] = useState<Project | null>(null)
    const [createOpen, setCreateOpen] = useState(false)

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    async function loadProjectsPage() {
        setLoading(true)
        Api.getProjects(await getAccessToken(), page, pageLength)
            .then(pageProjects => {
                projectsRef.current = pageProjects["items"]
                setProjects(sortByUpdatedAt(projectsRef.current))
                setTotalProjects(pageProjects["total"])
                setLoading(false)
            }).catch(console.error)
    }


    async function onDeleteProject(project: Project) {
        setDeleteLoading(true)
        Api.deleteProject(await getAccessToken(), project.id)
            .then(() => {
                setDeleteLoading(false)
                setSelectedProject(null)
            })
    }


    useEffect(() => {
        newElementsRef.current = []
        setNewElements(newElementsRef.current)
        loadProjectsPage()
    }, [page])

    useEffect(() => {
        function onProjectAdd(added: Project) {
            projectsRef.current = onResourceAdd(added, projectsRef.current)
            newElementsRef.current = onResourceAdd(added, newElementsRef.current)
            setNewElements(newElementsRef.current)
        }

        function onProjectUpdate(updated: Project) {
            projectsRef.current = onResourceUpdate(updated, projectsRef.current)
            newElementsRef.current = []
            setNewElements(newElementsRef.current)
            setPage(1)
            loadProjectsPage()
        }

        function onProjectDelete(deleted: Project) {
            projectsRef.current = onResourceDelete(deleted, projectsRef.current)
            
            newElementsRef.current.forEach(element => {
                if (element.id === deleted.id) {
                    newElementsRef.current = onResourceDelete(deleted, newElementsRef.current)
                    setNewElements(newElementsRef.current)
                }
            });

            loadProjectsPage()
        }

        socket.on("project:add", onProjectAdd)
        socket.on("project:update", onProjectUpdate)
        socket.on("project:delete", onProjectDelete)

        return () => {
            socket.off("project:add", onProjectAdd)
            socket.off("project:update", onProjectUpdate)
            socket.off("project:delete", onProjectDelete)
        }
    }, [socket])

    return (
        <>
            <main>
                <Container>
                    <Flex
                        justify="space-between"
                        align="center"
                        direction="row"
                        mt={rem(50)}
                        mb={rem(30)}
                    >
                        <Title size="h1">Projects</Title>
                        <Button
                            onClick={() => setCreateOpen(true)}
                            leftSection={<IconCirclePlus size={16}/>}
                        >
                            Create Project
                        </Button>
                    </Flex>
                    <ProjectsDataTable
                        projects={projects}
                        newProjects={newElements}
                        loading={loading}
                        onCreate={() => setCreateOpen(true)}
                        onSelection={project => setSelectedProject(project)}
                    />
                    <PaginationControl 
                        totalElements={totalProjects}
                        page={page}
                        pageLength={pageLength}
                        onChange={setPage}
                    />

                    {selectedProject != null &&
                        <ProjectDrawer
                            project={selectedProject}
                            opened={true}
                            onClose={() => setSelectedProject(null)}
                            deleteLoading={deleteLoading}
                            onDelete={onDeleteProject}
                        />
                    }
                </Container>
                <ProjectCreateModal
                    opened={createOpen}
                    setOpened={setCreateOpen}
                />
            </main>
        </>
    )
}

export default AdminProjectsList
