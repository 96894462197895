import { Menu, Button, rem } from '@mantine/core';
import {
  IconSettings,
  IconMenu2,
  IconLogout,
  IconLayoutList
} from '@tabler/icons-react';
import { Link } from 'react-router-dom';

function HeaderMenu({onLogout}:{onLogout: () => void}) {
  return (
    <Menu shadow="md" 
        width={200}
        offset={20}
        position='bottom-end'>
      <Menu.Target>
        <Button variant='transparent' color='black'>{<IconMenu2/>}</Button>
      </Menu.Target>
      <Menu.Dropdown>

        <Menu.Item component={Link} to="/dashboard" leftSection={<IconLayoutList style={{ width: rem(14), height: rem(14) }} />} >
          Dashboard
        </Menu.Item>
        <Menu.Item component={Link} to="/admin" leftSection={<IconSettings style={{ width: rem(14), height: rem(14) }} />} >
          Admin
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }} />} onClick={onLogout}>
          Log out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

export default HeaderMenu;