import { useAutoAnimate } from '@formkit/auto-animate/react'
import { useAuth0 } from "@auth0/auth0-react"

import { ActionIcon, Divider, Table, TextInput, UnstyledButton, Anchor } from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconCirclePlus, IconTrash } from '@tabler/icons-react'

import classes from "./InstancesTable.module.css"
import { Project, Instance } from '../../../interfaces'
import Api from '../../../api'
import { useApiErrorHandler } from '../../../hooks'
import InstanceModal from './Detail'
import { useState } from 'react'

interface InstanceForm {
    tag: string
}

function InstancesTable({ project, instances }: { project: Project, instances: Instance[] | null }) {
    const [parent] = useAutoAnimate()
    const { getAccessTokenSilently } = useAuth0()
    const handleError = useApiErrorHandler()
    const [selectedInstance, setSelectedInstance] = useState<Instance>()
    const [detailOpened, setDetailOpened] = useState<boolean>(false)

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    const createInstanceform = useForm({
        initialValues: {
            tag: "",
        },
        validate: {
            tag: value => value.length < 1
        }
    })

    async function onSubmitCreateInstance(values: InstanceForm) {
        Api.createInstance(await getAccessToken(), project.id, values.tag)
            .then(() => {
                createInstanceform.reset()
            }).catch((err) => {
                console.error(err);
                handleError(err)
            })
    }

    function onSelectInstance(instance: Instance) {
        setSelectedInstance(instance)
        setDetailOpened(true)
    }

    async function deleteInstance(instanceId: number) {
        Api.deleteInstance(await getAccessToken(), instanceId)
            .catch(console.error)

    }

    return (
        <>
            <form onSubmit={createInstanceform.onSubmit(onSubmitCreateInstance)}>
                <TextInput
                    withAsterisk
                    required
                    placeholder="Add new instance"
                    {...createInstanceform.getInputProps("tag")}
                    rightSection={<UnstyledButton type="submit"><IconCirclePlus size={16} /></UnstyledButton>}
                />
            </form>
            <Divider my="sm" />
            <div className={classes.content}>
            {instances && (
                <Table mt="md" withTableBorder>
                    <Table.Tbody ref={parent}>
                        {instances.map(instance => {
                            return (
                                <Table.Tr key={instance.id} className={classes.formRow}>
                                    <Table.Td>
                                        <Anchor onClick={() => onSelectInstance(instance)}>
                                            {instance.tag}
                                        </Anchor>
                                    </Table.Td>
                                    <Table.Td>
                                        <ActionIcon variant="subtle" color="red" aria-label={"Delete"} onClick={() => deleteInstance(instance.id)}>
                                            <IconTrash size={16} />
                                        </ActionIcon>
                                    </Table.Td>
                                </Table.Tr>
                            )
                        })}
                    </Table.Tbody>
                </Table>
            )}
            </div>

            {selectedInstance && 
                <InstanceModal 
                    instance={selectedInstance} 
                    opened={detailOpened} 
                    onClose={function (): void {setDetailOpened(false)} }
                />
            }
        </>
    )
}

export default InstancesTable