//
//
//  Utils
//
//

import { RequestError } from "./api.ts";
import { ChartData } from "./interfaces.ts";

export function formatDate(date: Date | string): string {
    if (date instanceof Date) {
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    }
    const parsedDate = new Date(date);
    
    if (!isNaN(parsedDate.getTime())) {
        return `${parsedDate.getFullYear()}-${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}-${parsedDate.getDate().toString().padStart(2, '0')}`;
    }
    return '';
}

export function sortByUpdatedAt(array: Array<any>) {
    const copyArray = [...array]
    copyArray.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
    return copyArray
}

export function sortByCreatedAt(array: Array<any>) {
    const copyArray = [...array]
    copyArray.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    return copyArray
}


export function onResourceAdd(newResource: any, listResources: any[]) {
    const index = listResources.findIndex(resource => resource.id === newResource.id)
    if (index < 0) {
        listResources.push(newResource)
        return [...listResources]
    } else {
        return listResources
    }
}

export function onResourceUpdate(updatedResource: any, listResources: any[]) {
    return listResources.map(res => {
        return res.id === updatedResource.id ? updatedResource : res
    })
}

export function onResourceDelete(deletedResource: any, listResources: any[]) {
    return listResources.filter(res => res.id != deletedResource.id)
}

export function raiseForStatus(response: Response) {
    if (!response.ok) {
        throw new RequestError(response)
    }
    return response
}


export function toJsonOrRaise(response: Response) {
    if (!response.ok) {
        throw new RequestError(response)
    }

    return response.json()
}


export function orderByDateAsc(data: ChartData[]): ChartData[] {
    return data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
}


export function formatNumber(number: number): string {
    return number.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function getDatesBetweenDates(startDate: Date, endDate: Date) {
    const dates = [];
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        dates.push(formatDate(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
}