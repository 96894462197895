//
//
//  Dashboard
//
//

import {Outlet} from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout.tsx";

function Dashboard() {
    return (
        <DashboardLayout>
            <Outlet/>
        </DashboardLayout>
    )
}

export default Dashboard
