//
//
//  List
//
//

import {Grid, ListItem, Card, Divider, DateRangePicker, DateRangePickerValue} from "@tremor/react";
import {Flex, Image, LoadingOverlay, Skeleton, Text, Title, rem} from "@mantine/core";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {Project, ProjectTotalCost} from "../../interfaces.ts"
import Api from "../../api.ts";
import {useAuth0} from "@auth0/auth0-react";
import PaginationControl from "../../components/PaginationControl.tsx";
import { formatDate, formatNumber } from "../../utils.ts";



function DashboardList() {

    const { getAccessTokenSilently } = useAuth0()
    const [projects] = useState<Project[]>([])
    const [totalCosts, setTotalCosts] = useState<ProjectTotalCost[]>([])
    const [sumTotalCosts, setSumTotalCosts] = useState<number>(0)
    const [areProjectsLoading, setAreProjectsLoading] = useState<boolean>(true)
    const [page, setPage] = useState(1)
    const pageLength = 10

    const today = new Date()
    const previousMonth = new Date()
    previousMonth.setMonth(previousMonth.getMonth() - 1)
    const [dateRange, setDateRange] = useState<DateRangePickerValue>({from: previousMonth, to: today})


    async function fetchProjectsCostsTotal(dateRange: any) {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
        })
        const params = new URLSearchParams({
            start_date: formatDate(dateRange["from"]),
            end_date: formatDate(dateRange["to"])
        })
        await Api.getProjectsCostTotal(accessToken, params)
        .then((data) => {
            setTotalCosts(data)
            setSumTotalCosts(data.reduce((acc, project) => acc + project.total_cost, 0))
            setAreProjectsLoading(false)
        })
    }

   
    useEffect(() => {
        setAreProjectsLoading(true)
        fetchProjectsCostsTotal(dateRange)
    }, [dateRange])
    


    return (    <>
                <Flex
                justify="space-between"
                align="center"
                direction="row"
                mt={rem(50)}
                mb={rem(30)}
            >
                    <Title >Dashboard</Title>
                    <DateRangePicker
                        value={dateRange}
                        onValueChange={setDateRange}
                        weekStartsOn={1}
                        enableClear={false}
                    />
                </Flex>
                <Card className="mx-auto bg-slate-50">
                <Link to={`overview`}>
                <Flex justify={'space-between'} align={'center'} gap={14} direction={'row'}>
                    <Image
                        radius="md"
                        h={60}
                        w="auto"
                        fit="contain"
                        src={'https://storage.googleapis.com/maia-billing-assets/maia-cognitive.svg'}
                    />
                    <p className="text-3xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">{formatNumber(sumTotalCosts)} €</p>
                </Flex>
                </Link>
                </Card>
                <Divider>Projects</Divider>
                
                <Grid numItems={1} className="gap-8">
                        {areProjectsLoading ? (
                        <>
                        <Skeleton visible={true} height={100}/>
                        <Skeleton visible={true} height={100}/>
                        <Skeleton visible={true} height={100}/>
                        </>) :(totalCosts.map((project: ProjectTotalCost) => {
                            return (
                                <Card key={project.project_id} className="mx-auto">
                                    <Link to={`/dashboard/project/${project.project_id}`}>
                                    <ListItem>
                                        <span>
                                            <Flex justify={'center'} align={'center'} gap={14} direction={'row'}>
                                                <Image
                                                    radius="md"
                                                    h={40}
                                                    w="auto"
                                                    fit="contain"
                                                    src={project.project_logo}
                                                />
                                                <Text fw={700} size="lg">{project.project_name}</Text>
                                            </Flex>
                                        </span>
                                        <span className="text-lg text-tremor-content-strong dark:text-dark-tremor-content-strong">{formatNumber(project.total_cost)} €</span>
                                    </ListItem>
                                    </Link>
                                </Card>
                            )
                        }))}
                </Grid>
                <PaginationControl
                    totalElements={projects.length}
                    page={page}
                    pageLength={pageLength}
                    onChange={setPage}
                 />
                 <LoadingOverlay visible={areProjectsLoading} loaderProps={{ size: "xl", variant: "oval" }} />
                </>
    )

}

export default DashboardList