import { Card } from '@tremor/react';
import { formatNumber } from '../utils';


export function TotalCostProjectCard({ totalCost, label }: {totalCost: number, label: string}) {
  return (
    <Card
      className=" max-w-xs"
      decoration="top"
      decorationColor="blue"
    >
      <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">{label}</p>
      <p className="text-3xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">{formatNumber(totalCost)} €</p>
    </Card>
  );
}

export default TotalCostProjectCard;
