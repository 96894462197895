//
//
//  Azure Chart
//
//

import {AreaChart, Card, Metric} from "@tremor/react";
import {Flex, Title} from "@mantine/core";
import {Billing} from "../interfaces.ts";
import { formatNumber } from "../utils.ts";


function ServiceBillingChart({billing, service}: {billing: Billing, service: string}) {

    return (
        <Card>
            <Flex justify="space-between">
                <Title>{service}</Title>
                <Flex direction="column" gap={10}>
                    <Metric className="text-right">{formatNumber(billing["total_cost"])} €</Metric>
                </Flex>
            </Flex>
            <AreaChart
                className="h-72 mt-4"
                data={billing['daily_cost']}
                index="date"
                yAxisWidth={65}
                categories={["cost"]}
                showLegend={false}
            />
        </Card>
    )
}

export default ServiceBillingChart
