//
//
//  Project
//
//

//
//
//  Project
//
//

import { Link, useParams } from "react-router-dom";
import {
  Anchor,
  Box,
  Flex,
  Grid,
  Image,
  LoadingOverlay,
  Skeleton,
  Title,
  rem,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import {
  Billing,
  Instance,
  InstanceService,
  Project,
  Project as ProjectInterface,
} from "../../interfaces.ts";
import {
  DateRangePicker,
  DateRangePickerValue,
  Tab,
  TabGroup,
  TabList,
} from "@tremor/react";
import Api from "../../api.ts";
import { useAuth0 } from "@auth0/auth0-react";
import TotalCostProjectCard from "../../components/TotalCostProjectCard.tsx";
import { formatDate, getDatesBetweenDates } from "../../utils.ts";
import ServiceBillingChart from "../../components/ServiceBillingChart.tsx";

function DashboardProject() {
  const today = new Date();
  const previousMonth = new Date();
  previousMonth.setMonth(previousMonth.getMonth() - 1);
  const { getAccessTokenSilently } = useAuth0();
  const [project, setProject] = useState<ProjectInterface | null>(null);
  const [billing, setBilling] = useState<Billing[] | null>(null);
  const [totalCostByInstance, setTotalCostByInstance] = useState<number>(0);
  const [isInstanceLoading, setIsInstanceLoading] = useState<boolean>(false);
  const [instances, setInstances] = useState<Instance[]>([]);
  const [activeInstance, setActiveInstance] = useState<Instance | null>(null);
  const instancesRef = useRef<Instance[]>([]);
  const [dateRange, setDateRange] = useState<DateRangePickerValue>({
    from: previousMonth,
    to: today,
  });

  const params = useParams();
  const projectId = params["projectId"];

  async function getAccessToken() {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      },
    });

    return accessToken;
  }

  async function fetchProject(projectId: string) {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      },
    });
    const project = await Api.getProject(accessToken, parseInt(projectId));
    setProject(project);
    await getInstances(project);
  }

  async function getInstances(project: Project | null) {
    if (project) {
      if (activeInstance == null) {
        Api.getProjectInstances(await getAccessToken(), project.id)
          .then(async (listInstances) => {
            instancesRef.current = listInstances;
            setInstances(listInstances);
            setActiveInstance(listInstances[0]);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }

  useEffect(() => {
    setIsInstanceLoading(true);
    setTotalCostByInstance(0);
    if (activeInstance == null) {
      return;
    }
    getInstanceServices(activeInstance.id);
  }, [activeInstance, dateRange]);

  async function getInstanceServices(instanceId: number | null) {
    if (instanceId) {
      Api.getInstanceServices(await getAccessToken(), instanceId)
        .then((listInstances) => {
          instancesRef.current = listInstances;
          fetchCostByService(instanceId, listInstances, dateRange);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  useEffect(() => {
    if (projectId == null) {
      return;
    }
    fetchProject(projectId).catch(console.error);
  }, [projectId]);

  async function fetchCostByService(
    instanceId: number,
    services: InstanceService[] | null,
    dateRange: any
  ) {
    const params = new URLSearchParams({
      start_date: formatDate(dateRange["from"]),
      end_date: formatDate(dateRange["to"]),
    });
    const newBillingDataArray: Billing[] = [];
    if (instanceId != null && services != null) {
      await Promise.all(
        services.map(async (service) => {
          await Api.getInstanceServiceCostDaily(
            await getAccessToken(),
            instanceId,
            service.service,
            params
          ).then((data) => {
            const totalCost = data.reduce(
              (acc, current) => acc + current.cost,
              0
            );
            const dailyCostMap = new Map(
              data.map((item) => [item.date, item.cost])
            );
            const dates = getDatesBetweenDates(dateRange.from, dateRange.to);
            const dailyCostData = dates.map((date) => ({
              date,
              cost: dailyCostMap.get(formatDate(date)) || 0,
            }));
            const newBillingData: Billing = {
              service_id: service.id,
              service_name: service.service,
              total_cost: totalCost,
              daily_cost: dailyCostData,
            };
            newBillingDataArray.push(newBillingData);
          });
        })
      );
    }
    const totalCostFromArray = newBillingDataArray.reduce(
      (acc, item) => acc + item.total_cost,
      0
    );

    setIsInstanceLoading(false);
    setBilling(newBillingDataArray);
    setTotalCostByInstance(totalCostFromArray);
  }

  if (project == null) {
    return (
      <LoadingOverlay visible loaderProps={{ size: "xl", variant: "oval" }} />
    );
  }

  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        direction="row"
        mt={rem(50)}
        mb={rem(50)}
      >
        <Flex className="mt-2" direction={"column"}>
          <Flex justify={"center"} align={"center"} gap={14} direction={"row"}>
            <Image
              radius="md"
              h={60}
              w="auto"
              fit="contain"
              src={project.logo_url}
            />
            <Title>{project.name}</Title>
          </Flex>
          <Anchor
            c="dimmed"
            size="sm"
            className="mt-5"
            component={Link}
            to="/dashboard"
          >
            <Flex align="center">
              <IconArrowLeft
                style={{ width: rem(12), height: rem(12) }}
                stroke={1.5}
              />
              <Box ml={5}>Back to Dashboard</Box>
            </Flex>
          </Anchor>
        </Flex>
        <Flex justify={"center"} direction={"column"} gap={14}>
          <TotalCostProjectCard
            label={
              activeInstance ? `Total Cost ${activeInstance.tag}` : "Total Cost"
            }
            totalCost={totalCostByInstance}
          />
          {activeInstance && (
            <TabGroup
              onIndexChange={(index) => setActiveInstance(instances[index])}
            >
              <TabList variant="solid" defaultValue={activeInstance?.id}>
                {instances.map((instance: Instance) => {
                  return (
                    <Tab key={instance.id} value={instance.id}>
                      {instance.tag}
                    </Tab>
                  );
                })}
              </TabList>
            </TabGroup>
          )}
        </Flex>
      </Flex>
      <Flex justify={"end"} w={"100%"}>
        <DateRangePicker
          value={dateRange}
          onValueChange={setDateRange}
          weekStartsOn={1}
          enableClear={false}
        />
      </Flex>
      <Skeleton className="mt-10" visible={isInstanceLoading} height={500}>
        <Grid className="gap-16 mb-10">
          {billing !== null &&
            billing.map((billingItem: any, index: number) => (
              <Flex key={index} className="mt-10 w-full mb-10">
                <ServiceBillingChart
                  key={billingItem.service_id}
                  billing={billingItem}
                  service={billingItem.service_name}
                />
              </Flex>
            ))}
        </Grid>
      </Skeleton>
      <LoadingOverlay
        visible={isInstanceLoading}
        loaderProps={{ size: "xl", variant: "oval" }}
      />
    </>
  );
}

export default DashboardProject;
