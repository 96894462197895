//
//
//  API
//
//


import {Project, Instance, ProjectTotalCost, DailyCost} from "./interfaces.ts";
import { raiseForStatus } from "./utils.ts";

export class RequestError extends Error {

    response: Response

    constructor(response: Response) {
        super()
        this.response = response
    }
}

export function toJsonOrRaise(response: Response) {
    if (!response.ok) {
        throw new RequestError(response)
    }
    return response.json()
}

class Api {

    static async getProjects(accessToken: string, page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/projects?" + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getProject(accessToken: string, projectId: number): Promise<Project | null> {
        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }
        })

        return toJsonOrRaise(response)
    }

    static async createProject(accessToken: string, name: string, logoUrl?: string) {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "name": name,
                "logo_url": logoUrl
            })
        })
        return toJsonOrRaise(response)
    }

    static async updateProject(accessToken: string, projectId: number, values: NonNullable<unknown>): Promise<Project | null> {
        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })

        return toJsonOrRaise(response)
    }

    static async deleteProject(accessToken: string, projectId: number) {
        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return raiseForStatus(response)
    }

    static async getProjectInstances(accessToken: string, projectId: number): Promise<Instance[]> {
        const response = await fetch(import.meta.env.VITE_API_URL + `/api/projects/${projectId}/instances`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }
        })

        return toJsonOrRaise(response)
    }

    static async getServices(accessToken: string) {
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/services", {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getInstances(accessToken: string, page: number, size: number=10) {
        const response = await fetch(import.meta.env.VITE_API_URL + "/api/instances?" + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getInstance(accessToken: string, instanceId: number): Promise<Instance | null> {
        const response = await fetch(import.meta.env.VITE_API_URL + `/api/instances/${instanceId}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            }
        })

        return toJsonOrRaise(response)
    }

    static async createInstance(accessToken: string, projectId: number, tag: string) {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/instances`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "project_id": projectId,
                "tag": tag
            })
        })
        return toJsonOrRaise(response)
    }

    static async updateInstance(accessToken: string, instanceId: number, values: NonNullable<unknown>): Promise<Instance | null> {
        const response = await fetch(import.meta.env.VITE_API_URL + `/api/instances/${instanceId}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })

        return toJsonOrRaise(response)
    }

    static async deleteInstance(accessToken: string, instanceId: number) {
        const response = await fetch(import.meta.env.VITE_API_URL + `/api/instances/${instanceId}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return raiseForStatus(response)
    }

    static async getInstanceServices(accessToken: string,  instanceId: number) {
        const response = await fetch(import.meta.env.VITE_API_URL + `/api/instances/${instanceId}/services`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async createInstanceService(accessToken: string, instanceId: number, service: string, referenceId: string) {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/instanceservices`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "instance_id": instanceId,
                "service": service,
                "reference_id": referenceId
            })
        })
        return toJsonOrRaise(response)
    }

    static async updateInstanceService(accessToken: string, instanceServiceId: number, values: NonNullable<unknown>): Promise<Instance | null> {
        const response = await fetch(import.meta.env.VITE_API_URL + `/api/instanceservices/${instanceServiceId}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })

        return toJsonOrRaise(response)
    }

    static async deleteInstanceService(accessToken: string, instanceServiceId: number) {
        const response = await fetch(import.meta.env.VITE_API_URL + `/api/instanceservices/${instanceServiceId}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return raiseForStatus(response)
    }

    static async getProjectsCostTotal(accessToken: string, params: URLSearchParams): Promise<ProjectTotalCost[]> {
        const response = await fetch(import.meta.env.VITE_API_URL + `/api/costs/projects/total?${params}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getProjectCostDaily(accessToken: string, projectId: number, params: URLSearchParams): Promise<DailyCost[]> {
        const response = await fetch(import.meta.env.VITE_API_URL + `/api/costs/projects/${projectId}/daily?${params}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

    static async getInstanceServiceCostDaily(accessToken: string, instanceId: number, service: string, params: URLSearchParams): Promise<DailyCost[]> {
        const response = await fetch(import.meta.env.VITE_API_URL + `/api/costs/instances/${instanceId}/services/${service}/daily?${params}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            }
        })

        return toJsonOrRaise(response)
    }

}

export default Api
