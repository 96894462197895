import { useAuth0 } from "@auth0/auth0-react";

import { Button, Group, LoadingOverlay, Modal, TextInput } from "@mantine/core";
import { useState } from "react";
import { useForm } from "@mantine/form";

import { useApiErrorHandler } from "../../../hooks.ts";
import Api from "../../../api.ts";


interface ProjectForm {
    name: string,
    logo_url: string
}

function ProjectCreateModal({opened, setOpened}: {
    opened: boolean,
    setOpened: (open: boolean) => void
}) {
    const { getAccessTokenSilently } = useAuth0()
    const handleError = useApiErrorHandler()
    const [createLoading, setCreateLoading] = useState(false)

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    const projectForm = useForm<ProjectForm>({
        initialValues: {
            name: "",
            logo_url: "https://storage.googleapis.com/maia-billing-assets/maia-billing-logo.svg"
        },
        validate: {
            name: value => value.length < 1
        }
    })



    function handleClose() {
        setOpened(false)
        projectForm.reset()
    }


    async function onSubmitCreate(values: ProjectForm) {
        setCreateLoading(true)
        Api.createProject(await getAccessToken(), values.name, values.logo_url)
            .then(() => {
                setCreateLoading(false)
                projectForm.reset()
                handleClose()
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setCreateLoading(false)
            })
    }
    
    return (
        <Modal opened={opened} onClose={() => handleClose()} size="65%" title={"Create project"} >
            <LoadingOverlay visible={createLoading}/>

            <form onSubmit={projectForm.onSubmit(onSubmitCreate)}>
                <TextInput
                    withAsterisk
                    label={"Name"}
                    placeholder={"Name"}
                    required
                    {...projectForm.getInputProps("name")}
                />

                <TextInput
                    withAsterisk
                    label={"Logo URL"}
                    placeholder={"Logo URL"}
                    required
                    {...projectForm.getInputProps("logo_url")}
                />
                
                <Group justify="center" mt={48}>
                    <Button type="submit">Create</Button>
                </Group>
            </form>

        </Modal>
    )
}

export default ProjectCreateModal